


































































































































































































































import Vue from "vue";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
  mdiMapMarker,
  mdiPhoneClassic,
  mdiPhone,
  mdiPlus,
  mdiStar,
} from "@mdi/js";

import {
  Company,
  CompanySpecialBold,
  CompanySpecialUpper,
  CompanySpecialDoble,
  CompanyPlanPaid,
  CompanyPlanFree,
} from "../store/types";
import CategoryList from "@/components/CategoryList.vue";
import { getUrl, OpenSocialLink } from "@/helpers";
export default Vue.extend({
  name: "Category",
  components: {
    CategoryList,
  },
  beforeMount() {
    this.Load();
  },
  data() {
    return {
      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
      mdiMapMarker,
      mdiPhoneClassic,
      mdiPhone,
      mdiPlus,
      mdiStar,

      CompanyPlanPaid,
      CompanyPlanFree,

      results: {
        category: null,
        companies: [],
      },
    };
  },
  computed: {
    companies(): Company[][] {
      const companies: Company[][] = [];
      const ccs = this.results.companies;
      let lastTown = "";
      let aux: Company[] = [];
      ccs.forEach((c: Company) => {
        if (c.town !== lastTown && lastTown !== "") {
          companies.push(aux);
          lastTown = c.town;
          aux = [];
          aux.push(c);
        } else {
          lastTown = c.town;
          aux.push(c);
        }
      });
      companies.push(aux);
      return companies;
    },
  },
  methods: {
    async Load() {
      this.results = await this.$store.dispatch(
        "directorio/GetCategory",
        this.$route.params.id
      );
    },
    isBold(specials: number) {
      return (specials & CompanySpecialBold) !== 0;
    },
    isUpper(specials: number) {
      return (specials & CompanySpecialUpper) !== 0;
    },
    isDoble(specials: number) {
      return (specials & CompanySpecialDoble) !== 0;
    },
    getSpecialClasses(specials: number) {
      let classes = "";
      classes += this.isBold(specials) ? "font-weight-bold " : "";
      classes += this.isUpper(specials) ? "text-uppercase " : "";
      return classes;
    },
    OpenSocialLink,
    getUrl,
  },
  watch: {
    $route: function () {
      this.Load();
    },
  },
});
