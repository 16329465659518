export interface SearchResults {
  search: string;
  events: Event[];
  companies: Company[];
}

export interface AdvertisingItem {
  id: string;
  website: string;
  logo_path: string;
}

export interface CarouselItem {
  id: string;
  path: string;
}

export interface Category {
  id: string;
  name: string;
  parent: string | null;
}
export interface PricingOption {
  id: string;
  name: string;
  image_path: string;
  price: number;
  type: "Físico" | "Virtual";
  location: "Normal" | "Especial";
  blocks: Block<BlockType>[];
}

export const CompanyPlanFree = 'Gratuito';
export const CompanyPlanPaid = 'Pago';
export const CompanySpecialBold = 1;
export const CompanySpecialUpper = 2;
export const CompanySpecialDoble = 4;
export interface Company {
  id: string;
  name: string;
  id_document: string;
  email: string;
  address: string;
  town: string;
  contact_name: string;
  contact_job_title: string;
  telephone: string;
  phone: string;
  blocks?: Block<BlockType>[];
  website?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
  rooms?: number; // Salones
  capacity?: string; // Aforo de personas
  gallery?: string[];
  plan: string;
  specials: number;
  logo_path?: string;
}

export interface Article {
  id: string;
  name: string;
  blocks?: Block<BlockType>[];
  short_description: string;
  image_path: string;
}

export interface Event {
  id: string;
  name: string;
  blocks?: Block<BlockType>[];
  short_description?: string;
  thumbnail_path: string;
  start_date?: Date;
  end_date?: Date;
  website?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
}

type BlockTypeToData = {
  paragraph: BlockParagraph;
  header: BlockHeader;
  list: BlockList;
  delimiter: {};
  table: BlockTable;
};

export type BlockType = keyof BlockTypeToData;
// type BlockData = BlockTypeToData[keyof BlockTypeToData];

export interface Block<T extends BlockType> {
  type: T;
  data?: BlockTypeToData[T];
}

export interface BlockParagraph {
  text: string;
}

export interface BlockHeader {
  text: string;
  level?: number; // 1 | 2 | 3 | 4 | 5 | 6
}

export interface BlockList {
  style: string; // ordered | unordered
  items: string[];
}

// export interface BlockDelimiter {}

export interface BlockTable {
  content: string[][];
}

// ////////////////////////////

export interface ResponseEssentials {
  advertisingItems: AdvertisingItem[];
  categories: Category[];
}

export interface ResponseIndex {
  carouselItems: CarouselItem[];
  articles: Article[];
}

export interface ResponseEvents {
  events: Event[];
}

export interface ResponseCalendar {
  events: Event[];
}

export interface ResponsePricing {
  pricing: PricingOption[];
}
